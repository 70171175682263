<template>
  <ion-page>
    <ion-content>
      <ion-slides pager="true" :options="slideOpts">
        <ion-slide
          v-for="(item, index) in slideImages"
          :key="index"
          @click="naviagateToSignIn(index)"
        >
          <img style="height: 100vh" :src="item.src" />
        </ion-slide>
      </ion-slides>
    </ion-content>
  </ion-page>
</template>


<script>
import {
  IonSlides,
  IonSlide,
  IonPage,
  IonContent
} from "@ionic/vue";
import { defineComponent } from "vue";
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

export default defineComponent({
  components: {
    IonSlides,
    IonSlide,
    IonPage,
    IonContent
  },
  setup() {
    const slideOpts = {
      pagination: {
        type: "pogressbar",
      },
      preloadImages: true,
      updateOnImagesReady: false,
    };

    const slideImages = [
      {
        src: "/assets/views/first-time/1-iPhone8.png",
      },
      {
        src: "/assets/views/first-time/2-iPhone8.png",
      },
      {
        src: "/assets/views/first-time/3-iPhone8.png",
      },
      {
        src: "/assets/views/first-time/4-iPhone8.png",
      },
    ];
    return { slideOpts, slideImages };
  },
  methods: {
    naviagateToSignIn(index) {
      if (index == this.slideImages.length - 1) {
        Storage.set({ key: "first-time-tutorial-completed", value: "true" }).then(
          this.$router.replace("/")
        );
      }
    },
  },
});
</script>

<style scoped>
ion-slides {
  height: 100%;
}
ion-content,
ion-page {
  --background: #efeff4 !important;
}
</style>